// Here you can add other styles

/*------------------- login start ------------------*/

.login-bg {
	background: url(/image/admin_login.png) no-repeat, #FFFFFF;
    background-position: center;
    background-size: cover;
}

.login {
  border-radius: 1rem !important;
  background: #FAFAFA;
}

.login .btn:hover {
  color: #4E57AA;
}

.txt-color {
	color: #4E57AA;
}

.lang-btn {
  border: 1px solid #E7E5E5;
  padding: 5px 0.5rem !important;
  min-width: 100px;
}

.login label {
	font-weight: 500;
}

.login-btn {
	background: #4E57AA;
	border-radius: 0.5rem;
	width: 7rem;
    height: 2.5rem;
}

.login .form-control {
	background: #eef0f5;
}

.login ::placeholder {
	color: #939598 !important;
}
  
.login :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #939598 !important;;
}
  
.login ::-ms-input-placeholder { /* Microsoft Edge */
   color: #939598 !important;;
}

.txt-color img {
	width: 1.5rem;
  margin: 0 auto;
}

.lang-all {
	width: 50%;
}

#admin-form input[type="text"] {
  background: none !important;
}

textarea {
  resize: none !important;
  background-color: #F5F6FA !important;
}
  
textarea:disabled,
input[type="text"]:disabled {
  background-color: #D8DBE0 !important;
  opacity: 1 !important;
  cursor: not-allowed;
}

.custom-select {
  background: #F5F6FA url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat right 0.75rem center/8px 10px;
  background-size: 0.6rem 1.2rem;
  background-position-y: 0.7rem;
}
  
.custom-select:focus {
  box-shadow: none !important;
  background-color: #F5F6FA;
}
  
.custom-select:disabled {
  cursor: not-allowed;
  color: #333333;
}

.lang-fieldset {
  border-top: 1px solid #d8dbe0; 
}

.lang-fieldset legend {
  width: 50%;
  font-size: 0.8rem;
}

.login-form {
  margin: 0 auto;
}

.brc-login {
  height: 3rem;
  top: 0rem;
  left: 1.5rem;
  position: absolute;
  z-index: 1000;
}

.login .login-body {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

@media (max-width: 350px) {
	.login-form {
		width: 300px;
	}

  .login .login-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .login-body form {
    margin-top: 0.5rem;
  }
  
  .login-bg .brc-login {
    margin-top: 0px;
  }
}

@media (min-width: 350.1px) and (max-width: 767.98px) {
	.login-form {
		width: 370px;
	}
}

@media (min-width: 768px) {
	.login-form {
		width: 450px;
		margin: 0 auto;
	}
}

.login-input {
  position:relative;
  background:#eef0f5;
  border: 1px solid#d8dbe0;
  border-radius: 0.25rem;
}
.login-input input {
  margin:0;
  padding-right: 30px;
  width: 100%;
  border: none;
}
.login-input img {
  width: 20px;
  height: 20px;
  padding: 3px;
  margin: 7px;
}

/*------------------- login end ------------------*/

/*------------------- reset password start ---------------- */

.reset-pwd .reset-btn {
	margin: 1.3rem;
	background: #4E57AA;
	color: #000000;
	border-color: #E7E5E5;
	border-radius: 6px;
	padding: 8px 25px 8px 25px;
	min-width: 5.6rem;
}

.reset-pwd {
  border-radius: 1rem !important;
  background: #FAFAFA;
}

.reset-pwd .ok-btn {
	background: #4E57AA;
	border-radius: 0.5rem;
	width: 7rem;
  height: 2.5rem;
}

.reset-pwd .submit-btn {
	width: 6.5rem;
	background: #4E57AA;
	border-radius: 0.5rem;
}

.reset-pwd .form-control {
	background: #eef0f5;
}

.email-btn-block {
	margin-top: 6rem;
}

.txt-block {
	min-height: 10rem;
	padding: 1.2rem;
}

.reset-info-msg {
  // margin-top: -15px;
  margin-bottom: 15px;
  color: #ff0000;
}

.eye {
  position: absolute;
  z-index: 9999;
  top: 15%;
  right: 20px;
}

@media (max-width: 386px) {
	.forgot-form {
		width: 262px;
	}
	.reset-pwd .reset-btn {
    margin: 0.5rem;
  }
  .btn-toolbar {
    margin-top: 2rem !important;
  }
}

@media (min-width:386px) {
	.forgot-form {
		width: 380px;
	}
}

@media (max-width: 280px) {
  .reset-form {
		width: 262px;
	}
}

@media (max-width: 337px) {
  .accSecurity ::placeholder {
		font-size: 0.54rem;
	}
}

@media (min-width:480px) {
  .reset-form {
		width: 460px;
	}
}

@media (max-width: 576px) {
	.submit-btn:first-child {
		margin-right: 0.3rem;
	}
	.submit-btn:last-child {
		margin-left: 0.3rem;
	}
}

/*------------------- reset password end ---------------- */

body {
  font-family: "Titillium Web", sans-serif;
}

.container {
  color: #333333 !important;
}

// header test
.c-header,
.c-subheader,
.c-body {
  background: #f5f6fa;
}

// Sidebar start

.c-sidebar {
  width: 244px;
}

.c-body .c-main {
  padding-top: 1rem;
}

c-sidebar-nav {
  display: block;
}

.c-sidebar,
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #ffffff;
  color: #4e57aa;
}

.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #c4cce3;
  color: #4e57aa;
}

.c-sidebar-nav-link {
  word-break: break-word;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-title,
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {  
  color: #4E57AA;
	white-space: break-spaces;
	// padding-right: 1.5rem;
	padding: 0.5rem 1.5rem;

	// animation page load
	-webkit-animation: moveFromLeft .2s ease-out;
	animation: moveFromLeft .4s ease-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #4e57aa;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #4e57aa;
  white-space: break-spaces;
}

// .c-sidebar .c-sidebar-nav-link.c-active,
// .c-sidebar .c-sidebar-nav-link:hover {
//   border-radius: 0 5rem 5rem 0;
//   background: #c4cce3;
//   color: #4e57aa;
// }

// .c-sidebar .c-sidebar-nav-link:hover,
// .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
//   background: #c4cce3;
//   border-radius: 0 5rem 5rem 0;
// }

.c-sidebar .c-sidebar-nav-link.c-active {
	border-radius: 0 5rem 5rem 0;
	background: #C4CCE3;
	color: #4E57AA;
	// bg color from animation
	background: linear-gradient(to left, #C4CCE3 10%, transparent 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
	background: #C4CCE3;
	color: #4E57AA;
	border-radius: 0 5rem 5rem 0;
	// animation hover
	background: linear-gradient(to left, #C4CCE3 10%, transparent 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.c-avatar {
  display: block;
  width: 7rem;
  height: auto;
  margin: 0.2rem auto;
  text-align: center;
}

.c-avatar .c-avatar-img {
  width: 85%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border: 2px solid #7bbe35;
  height: 95px;
}

.sidebar-bg {
  width: 100%;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar .c-sidebar-nav-dropdown-toggle::after,
.c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
  // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>");
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.5em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  flex: 0;
}

.c-sidebar-nav {
  margin-bottom: 3px;
}

.c-sidebar-nav > li > a::before {
  content: "";
  width: 1.5rem;
  height: 1.4rem;
  background-size: 1rem;
  display: inline-block;
  background-repeat: no-repeat;
  margin-top: 5px;
}

.c-sidebar-nav li ul li a::before {
  content: "";
  display: inline-block;
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 0.2rem;
  width: 0.2rem;
  margin-right: 0.5rem;
  background-color: #4e57aa;
}

.c-sidebar-nav > .overtime > a::before {
  background-image: url(/image/overtime_set_minute.png);
  background-size: 1.1rem 1.3rem;
}
.c-sidebar-nav > .holiday > a::before {
  background-image: url(/image/holiday_setup.png);
  background-size: 1.1rem 1.3rem;
}
.c-sidebar-nav > .package > a::before {
  background-image: url(/image/package_register.png);
  background-size: 1.1rem 1.2rem;
}
.c-sidebar-nav > .tenant_register > a::before {
  background-image: url(/image/tenant_register.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .company_register > a::before {
  background-image: url(/image/company_register.png);
  background-size: 1rem;
}
.c-sidebar-nav > .company_list > a::before {
  background-image: url(/image/company_list.png);
  background-size: 1rem;
}
.c-sidebar-nav > .client_register > a::before {
  background-image: url(/image/client_id.png);
  background-size: 1.3rem;
}
.c-sidebar-nav > .vendor_register > a::before {
  background-image: url(/image/vendor_register.png);
  background-size: 1rem;
}
.c-sidebar-nav > .leave > a::before {
  background-image: url(/image/leave_type.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .user_register > a::before {
  background-image: url(/image/user_register.png);
  background-size: 1rem;
}
.c-sidebar-nav > .payment_name > a::before {
  background-image: url(/image/payment_name.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .payment_setting > a::before {
  background-image: url(/image/payment_setting.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .invoice > a::before {
  background-image: url(/image/leave_type.png);
  background-size: 1.2rem;
}
.c-sidebar-nav > .calculate > a::before {
  background-image: url(/image/calculate.png);
  background-size: 1.2rem;
}
.c-sidebar-nav > .salary > a::before {
  background-image: url(/image/salary.png);
  background-size: 0.9rem;
}
.c-sidebar-nav > .tenant_list > a::before {
  background-image: url(/image/tenant_list.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .vendor_list > a::before {
  background-image: url(/image/vendor_list.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .user_list > a::before {
  background-image: url(/image/user_list.png);
  background-size: 1.1rem;
}
.c-sidebar-nav > .department > a::before {
  background-image: url(/image/department.png);
  margin-right: 0.5rem;
  // margin-top: -1.2rem;
}

.c-sidebar-nav > .role > a::before {
  background-image: url(/image/role_permission.png);
  margin-right: 0.5rem;
  // margin-top: -1.2rem;
}
.c-sidebar-nav > .company_profile > a::before {
  background-image: url(/image/company_profile.png);
  margin-right: 0.2rem;
}

.c-sidebar-nav > .database_cloud_storage > a::before {
  background-image: url(/image/Cloud.png);
  margin-right: 0.0rem;
}

/*----submit-btn-----*/

.green-btn button {
  background-image: linear-gradient(to bottom, #d0f2ac, #ffffff);
  border: 1px solid #d0f2ac;
}

.green-btn button:hover {
  background-image: linear-gradient(to bottom, #7bbe35, #ffffff);
}

.green-btn button:focus {
  background-image: linear-gradient(to bottom, #7bbe35, #ffffff);
}

.cal-bg {
  width: 12.3rem;
  background-image: linear-gradient(to bottom, #d0f2ac, #ffffff);
  border: 1px solid #d0f2ac;
}

.cal-bg:hover {
  background-image: linear-gradient(to bottom, #7bbe35, #ffffff);
}

.cal-bg:focus {
  background-image: linear-gradient(to bottom, #7bbe35, #ffffff);
  border: none;
}

.custom-icon {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #768192;
  background: #f5f6fa
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23d0f2ac'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat right 0.75rem center/8px 10px;
  // border-color: #d8dbe0;
  background-size: 0.6rem 1.2rem;
  background-position-y: 0.7rem;
}

.txt-wdith {
  width: 12.3rem;
}


.logout {
  position: fixed;
  bottom: 2.3rem;
  width: 15.25rem;
}

.logout a::before {
  background-image: url(/image/logout.png);
}

.d-lg-none svg {
  display: none;
}

.print a {
  text-decoration: underline;
}

ul .breadcrumb li a {
  position: relative;
  cursor: text;
  text-decoration: none;
  color: #8a93a2;
  pointer-events: none;
}

[type="date"] {
    background:#fff url(/image/calendar.png)  97% 50% no-repeat ;
    background-size: 1.3rem auto;
}
[type="date"]::-webkit-inner-spin-button {
    display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.m-0-auto {
  margin: 0 auto !important;
}

@media (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 244px;
  }
}

//  Sidebar End

//brycen logo header

.brc-lg {
  height: 3rem;
  margin-top: 7px;
}

.pr-width {
  padding-right: 2.5rem;
}

.d-img {
  border-radius: 50rem;
  width: 30px;
  margin-top: 4px;
}

//brycen logo header end

/*------------------------- button start -----------------------*/

.event-btn button,
.search-btn button {
  min-width: 6.3rem;
  background-image: linear-gradient(#C7CBF7, #FFFFFF);
  border: 1px solid #E7E5E5;
}
.event-btn button:hover,
.search-btn button:hover  {
  background-image: linear-gradient(#A1A8F1, #FFFFFF);
}
.event-btn button:focus,
.search-btn button:focus {
  background-image: linear-gradient(#A1A8F1, #FFFFFF);
}

/*------------------------- button end -----------------------*/

/*------------------------- message start -----------------------*/

.require {
  color: #F62B14;
}

.success {
  background-color: #B8F7B0;
  border: 1px solid #7F7F7F !important;
  color: #046C0A;
}

.error {
  background: #FFF2F2;
  border: 1px solid #F62B14 !important;
  color: #F62B14 !important;
}

/*------------------------- message end -----------------------*/

// ______________________ pagination start ___________________

.custom-pagination {
  background-color: #FFFFFF;
  
  border-bottom-left-radius: 15px;
  -moz-border-radius-bottomleft: 15px;
  -webkit-border-bottom-left-radius: 15px;
  
  border-bottom-right-radius: 15px;
  -moz-border-radius-bottomright: 15px;
  -webkit-border-bottom-right-radius: 15px;
}
  
.page-link {
  color: #000000;
  background-color: #F1F3F8;
  // border-color: #d8dbe0;
  border-left-color: #FFFFFF;
}
  
.page-item:first-child .page-link {
  color: #000000;
  border-left-color: #C4C9D0;
  background-color: #F1F3F8;
}
  
.page-item:first-child .page-link:hover {
  color: #000000;
  background-color: #7BBE35;
}
  
.page-item:first-child .page-link:focus {
  color: #000000;
}
  
.page-item:last-child .page-link {
  color: #000000;
  background-color: #F1F3F8;
}
  
.page-item:last-child .page-link:hover {
  color: #000000;
  background-color: #7BBE35;
}
  
.page-item:last-child .page-link:focus {
  color: #000000;
}
  
.page-link:hover {
  color: #FFFFFF;
  background-color: #7BBE35;
  border-color: #D8DBE0;
}
  
.page-item.active .page-link {
  background-color: #7BBE35;
  border-top-color: #C4C9D0;
  border-bottom-color: #C4C9D0;
  border-left-color: #FFFFFF;
  border-right-color: #FFFFFF;
}
  
.page-link:focus {
  // box-shadow: 0 0 0 0.2rem #D0F2AC;
  box-shadow: none;
}

// ______________________ pagination end _____________________

//_______________________ table start ________________________

.span-icon {
  padding-bottom: 2px;
}

button:focus {
  box-shadow: none!important;
}
  
.user-list-table .edit-btn {
  background-color: #B1AAF6;
  color: #FFFFFF;
  height: 29px;
}
  
.user-list-table .edit-btn:hover {
  background-color: #9593E2;
  color: #FFFFFF;
}
  
.user-list-table .edit-btn:focus {
  background-color: #9593E2;
}
  
.user-list-table .delete-btn {
  background-color: #F62B14;
  color: #FFFFFF;
  height: 29px;
}
  
.user-list-table .delete-btn:hover {
  background-color: #E20500;
  color: #FFFFFF;
}
  
.user-list-table .delete-btn:focus {
  background-color: #E20500;
}

.table #edit-btn {
  border-right: none !important;
}

#edit-btn,
#delete-btn {
    text-align: center;
}

@media(max-width: 575.98px) {
  .table {
    min-width: 600px;
  }
  .area-table {
    min-width: auto;
  }
  .percent-remove-table {
    min-width: auto;
  }
}

// ___________________ table end __________________

// ______________________ modal box start ______________________

.modal-header button {
  width: 3rem;
  height: 0.5rem;
  background: #4E57AA;
  color: #C7C7C7;
  font-size: 2.3rem;    
  border-radius: 50%;
  padding-bottom: 3rem !important;
  opacity: 1;
  font-weight: 100;
}
html:not([dir="rtl"]) .modal-header .close {
  margin: -2.5rem -2.5rem -1rem auto;
  padding: 0;
}
.modal-header .close:not(:disabled):not(.disabled):hover,
.modal-header .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
  outline: none;
}

.permission .modal-content {
  height: 465px;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.modal-content {
	border-radius: 1rem !important;
	margin-left: 7%;
	margin-right: 7%;
}

.modal-footer {
  border-top: none;
}

.modal-body #table {
  margin: 0 auto;
}

.confirm-header {
	padding-top: 1rem;
	padding-bottom: 0.5rem;
}

.confirm-body {
	padding-bottom: 0.6rem;
}

.confirm-btn {
	margin: 1rem;
	background-image: linear-gradient(to bottom, #C7CBF7, #FFFFFF);
	color: #000000;
	border-color: #E7E5E5;
	border-radius: 6px;
	padding: 8px 25px 8px 25px;
	min-width: 5.6rem;
}

.confirm-btn:hover {
	color: #000000;
	background-image: linear-gradient(to bottom, #A1A8F7, #FFFFFF);
}

.confirm-btn:focus {
	color: #000000;
	background-image: linear-gradient(to bottom, #A1A8F7, #FFFFFF);
}

.modal-p {
	font-size: 1rem;
}

.permission_name input {
  width: 11.6rem;
  margin: 0 auto;
  text-align: center;
}

.m-body-height {
  padding: 0;
  // min-height: 24rem;
}

.table-assign {
  margin-bottom: 1rem;
  word-break: break-all;
  border-collapse: inherit;
  border: 1px solid #d4d0d0;
  border-spacing: 0px;
  border-radius: 1.1rem;
  overflow-x: auto;
  max-height: 13rem;
}

.table-assign thead {
  background-image: linear-gradient(to right, #c4cce3, #b1aaf6);
  color: #ffffff;
  //  display:block;
  display: table;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.table-assign tbody {
  // min-width: 18.75rem;
  display: block;
  max-height: 10rem !important;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: block;
  max-height: 100px;
  // overflow-y: auto;
  border-bottom-left-radius: 1rem;
}

.table-assign thead th {
  position: sticky;
  top: 0;
  //  display: block;
  padding: 10px;
  vertical-align: middle;
  border-top: none;
  border-bottom: none;
  text-align: center;
  border-collapse: collapse;
}

// ______________________ modal box end ______________________

/*----------------------------------- custom loading -----------------------------*/
#overlay {
	display: block;
	z-index: 2000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
}

@media (min-width: 992px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 45%;
	}
}

@media (max-width: 991px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 42%;
	}
}

@media (max-width: 720px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 40%;
	}
}

@media (max-width: 662px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 40%;
	}
}

@media (max-width: 540px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 37%;
	}
}

@media (max-width: 500px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 37%;
	}
}

@media (max-width: 400px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 30%;
	}
}

@media (max-width: 280px) {
	#overlay div {
		border: none;
		position: relative;
		width: 100px;
		height: 100px;
		top: 40%;
		left: 25%;
	}
}

@keyframes play60 {
	0% {
		background-position: 0px 0px;
	}
	100% {
		background-position: -8400px 0px;
	}
}

.shapeshifter {
	animation-duration: 800ms;
	animation-timing-function: steps(60);
	width: 140px !important;
	height: 140px !important;
	background-repeat: no-repeat;
}

.shapeshifter.play {
	animation-name: play60;
	animation-iteration-count: infinite;
}
/*---------------------------------- end custom loading --------------------------*/

@media (max-width: 575px) {
  .confirm div {
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 392px) {
  .brc-lg {
    height: 2.2rem;
  }
  .d-img {
    width: 22px;
    margin-top: 0px;
  }
  .c-header-nav {
    padding-left: 0 !important;
  }
  .language a {
    padding-left: 0;
  }
  .language span {
    margin-top: 0 !important;
  }
  .pr-width {
    padding-right: 1.5rem;
  }
  .dropdown-item {
		padding-left: 10px !important;
	}
}
@media (max-width: 768px) {
  .c-header-nav {
    padding-right: 1rem !important;
  }
}

.card {
  animation: fadeIn .8s ease-out;
  border-radius: 1rem;
}

@keyframes fadeIn {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes moveFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::before {
	transform: rotate(360deg);
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
	transform: rotate(180deg);
}

html:not([dir="rtl"]) .show > .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropdown-item:hover:first-child {
	border-top-left-radius: 0.19rem;
	border-top-right-radius: 0.19rem;
}

.dropdown-item:hover:last-child {
	border-bottom-left-radius: 0.19rem;
	border-bottom-right-radius: 0.19rem;
}

.dropdown-item:active {
	background-color: #EBEDEF;
	color: lighten(black, 30);
}

/* common button start */

.form-btn {
	background-image: linear-gradient(to bottom, #C7CBF7, #FFFFFF);
	color: #000000;
	border-color: #E7E5E5;
	border-radius: 6px;
	min-width: 6rem;
	height: 2.5rem;

	-webkit-animation: moveFromBottom .5s ease-out;
	animation: moveFromBottom .5s ease-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
}
.form-btn:hover {
	color: #000000;
	background-image: linear-gradient(to bottom, #A1A8F7, #FFFFFF);
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px);
	-webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
	transition: all .2s ease-out;
}
.form-btn:focus {
	color: #000000;
	background-image: linear-gradient(to bottom, #A1A8F7, #FFFFFF);
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
}

@keyframes moveFromBottom {
	0% {
		opacity: 0;
		transform: translateY(6px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

/* common button end */

// department and position save button

.m-save-btn {
  margin-top: 1.7rem;
  
  @media (min-width: 768px) and (max-width: 910px) {
      padding: 0px;
  }
  @media (min-width: 991px) and (max-width: 1154px) {
      padding: 0px;
  }
}

/************ role and permission start ************/

.plus-minus{
  width: 22px;
  height: 22px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.title-bottom-line{
	border-bottom: 1px solid red;
	width: 80%;
}

.circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #b1aaf6;
  margin-left: 48%;
}

.cross {
  position: relative;
  height: 13px;
}
.cross::before {
  transform: rotate(45deg);
}
.cross::after {
  transform: rotate(-45deg);
}
.cross::after, .cross::before {
  position: absolute;
  content: "";
  height: 13px;
  width: 2px;
  background-color: #b1aaf6;
  border: 1px solid #b1aaf6;
}

.last_step {
  margin-right: 3px;
  margin-left: 100px;
  margin-top: 5px;
}
.mid_step {
  margin-bottom: 2px;
  margin-right: 3px;
  margin-left: 40px;
  margin-top: 5px;
}

@media (max-width: 400px) {
  .mid_step {
    margin-left: 25px;
  }
  .last_step {
    margin-left: 70px;
  }
}
@media (max-width: 991px) {
  .process {
    margin-top: 1rem;
  }
}

/************ role and permission end ************/

/************ company register start ************/

.table-responsive .company-tbl {
  min-width: 997px;
}
.admin-table,
.payment-setting-table {
  min-width: 917px;
}

.my-fieldset {
  border: 1px solid #d8dbe0;
}

.my-fieldset legend {
  width: auto;
}

@media (min-width: 767px) {
  .vendor_company {
    margin-top: -42px;
  }
}

// upload photo

#photo, .custom-file-label {
  width: calc(100% - 100px);
  margin-right: 75px;
}

#com_info .custom-file-label {
  right: 0;
  background-color: #ffffff;
}

.imgPreview {
  margin: 15px 0px;
  height: 131px;
  width: 141px;
  border-left: 1px solid #C4CCE3;
  border-right: 1px solid #C4CCE3;
  border-top: 5px solid #C4CCE3;
  border-bottom: 5px solid #C4CCE3;
}
.imgPreview img {
    width: 100%;
    height: 100%;
}  
.submitButton {
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
  &:hover {
    background: #efefef;
  }
}
.custom-file-label {
    right: 15px;
    left: 15px;
    background-color: #F5F6FA;
}
html:not([dir="rtl"]) .custom-file-label::after {
    right: 5px;
}
.custom-file-label::after {
    top: 4px;
    height: calc(1.5em + 0.3rem);
    padding: 0.1rem 0.5rem 0.3rem;
    background-color: #CFD4E2;
    border-radius: 0.4rem;
    border: 1px solid #CDCCCC;
    color: #333333;
    font-size: 0.78rem;
}

.photo-clear {
  width: 60px;
  height: calc(1.5em + 0.3rem);
  padding: 0.1rem;
  background-color: #CFD4E2;
  border-radius: 0.4rem;
  border: 1px solid #CDCCCC;
  color: #333333;
  font-size: 0.78rem;
  margin-top: 6px;
  right: 20px;
  position: absolute;
}

/************ company register end ************/

/* package registration start */

.pkg-table {
  min-width: 1029px;
}

.title-ground {
  color: #4e57aa;
}

.card-ground {
  background-color: #c4cce3;
}

label {
  word-break: break-word;
  padding-top: 2px;
}

/* package registration end */

/* company register start */

.card-body {
  padding: 1rem 2rem 0.5rem;
}
body {
  background-image: linear-gradient(to right, #C4CCE3, #FFFFFF);
}
label,
th {
  word-break: break-word;
}

/*-__________ common end _______________*/

.nav_title,
.nav_title .card-header {
  background: none;
  border: none;
  padding: 0.5rem;
}
.nav_title h2 {
  color: #4E57AA;
}
.nav-item .navLink {
  width: 5%;
  margin: 0 auto;
  padding: 13px;
  position: inherit;
  background-color: #ebedef;
  border: 2px solid #C4CCE3;
}
.nav .nav-item .active {
  background-color: #8BF022;
}
.nav li:nth-child(2) div {
  margin-left: 50%;
}
.nav li:nth-child(4) div {
  margin-right: 50%;
}
.contact-person .card-body {
  padding: 1rem 1.9rem 0.5rem;
}
.company-info .card-header,
.contact-person .card-header,
.company-package .card-header,
.clients_info .card-header {
  background: #C4CCE3;
  width: 15rem;
  border: none;
  margin: 1.2rem 1.5rem 0.3rem;
  padding: 0.5rem 1rem;
  font-size: 0.95rem;
}
.company-info .card-header:first-child,
.contact-person .card-header:first-child,
.company-package .card-header:first-child,
.clients_info .card-header:first-child {
  border-radius: calc(1.25rem - 2px) calc(1.25rem - 2px) calc(1.25rem - 2px) calc(1.25rem - 2px);
}
.card-footer {
  border-top: none;
  background: none;
}

.clients_info button {
  background: none;
  border: none;
}

// contact person tab

.btn-person {
  margin: 0 1rem 0.5rem;
}

// company package tab

.company-package .btn {
  margin: 3rem 1rem 1rem;
}

.package-body .card {
  background: #F5F6FA;
  margin-bottom: 0.7rem;
  border: none;
}

html:not([dir="rtl"]) .form-check {
  padding-left: 0;
}

html:not([dir="rtl"]) .pkg_check .form-check-input {
  margin-left: -0.25rem;
}

.package-body .pkg-check-label {
  margin-bottom: 0.5rem;
  width: calc(100% - 135px);
}

.package-body .custom-select {
  background-color: #E4E8F3;
  border: none;
  margin-left: 1rem;
  width: 5rem;
  height: 10%;
}

.package-body .version{
  background-color: #E4E8F3;
  border: none;
  margin-left: 1.5rem;
  width: 4rem;
  height: 10%;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}

input[type="checkbox"]:before {
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  width: 16px;
  height: 16px;
  margin: 0 .25em 0 0;
  vertical-align: top;
  background-color: #E4E8F3;
}
input[type="checkbox"]:checked:before {
  content: "\2713";
  text-align: center;
}

// .package-body .form-check-label::before {
//   content: "";
//   display: inline-block;
//   vertical-align: middle;
//   border-top: 0.6em solid #4E57AA;
//   border-right: 0.4em solid transparent;
//   border-bottom: 0;
//   border-left: 0.4em solid transparent;
//   margin-right: 0.5rem;
//   transform: rotate(30deg);
// }

.module label {
  width: calc(100% - 6.5rem);
}

// client info tab

.card-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.detail-btn {
  color: #4E57AA !important;
  padding: 0;
}

// Detail Information show with modal box start

.modal-header button {
  width: 3rem;
  height: 0.5rem;
  background: #4E57AA;
  color: #C7C7C7;
  font-size: 2.3rem;    
  border-radius: 50%;
  padding-bottom: 3rem !important;
  opacity: 1;
  font-weight: 100;
}
html:not([dir="rtl"]) .modal-header .close {
  margin: -2.5rem -2.5rem -1rem auto;
  padding: 0;
}
.modal-header .close:not(:disabled):not(.disabled):hover,
.modal-header .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
  outline: none;
}
.modal-header .close:hover {
  color: #FFFFFF;
}
.modal-title {
  padding-left: 0.8rem;
}
.modal-footer {
  border-top: none;
  margin: 0 auto;
}
.modal-footer button {
  text-align: center;
  margin: 0 auto;
  height: 2rem;
  background: linear-gradient(to bottom, #C7CBF7, #ffffff);
  border: 1px solid #E7E5E5;
  padding: 0.2rem 1.3rem 1.6rem;
  margin: 0 1rem;
}
.modal-footer .ok {
  padding: 0 2rem;
}
.modal-footer .cancel {
  padding: 0px 1.3rem;    
}

.del-txt .modal-header div {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 2rem;
  display: block;
}

.del-txt .modal-header {
  border: none;
}

.del-txt .modal-footer {
  margin: 0 auto;
  text-align: center;
}

.modal-body {
  text-align: left;
  max-height: 300px;
}

.company-modal-body {
  max-height: none;
}

.payment-modal .modal-body {
  max-height: none;
}

.modal-p {
font-size: 1rem;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.pkg_check {
  border: 1px solid #d8dbe0;
  padding: 3px 11px !important;
  border-radius: 5px;
  width: 85px;
  height: calc(1.5em + 0.6rem);
  padding: 0.1rem;
  border-radius: 0.4rem;
}

.pkg_check span {
  width: 50px;
  display: inline-block;
}

// Detail Information show with modal box end

@media(max-width: 575.98px) {
  .nav_title .nav {
      flex-wrap: initial;
  }
  .company-info .card-header,
  .contact-person .card-header,
  .company-package .card-header,
  .clients_info .card-header {
      width: auto;
  }
  .company-package .btn {
      margin-top: 0.5rem;
  }
}

@media(max-width: 767.98px) {
  .form-group {
      margin-bottom: 0.3rem;
  }
  .form-control,
  .custom-select {
      margin-bottom: 0.3rem;
  }
}

/* company register end */

input:-internal-autofill-selected {
  background-color: none !important;
}

/* payment setting register start */

.method1-active {
  margin-left: 7px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #EDEDED;
  border-bottom-style: hidden;
  // background-color: #FAFBFC;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: -1px;
  position: relative;
}
.method1-active > label {
  margin-bottom: 0px;
}
.method1-in-active > label {
  margin-bottom: 0px;
  color: #cccccc;
}
.method1-in-active{
  margin-left: 7px;
  border-radius: 12px 12px 0px 0px;
  border-bottom-style: hidden;
}
.method1:hover{
  // background-color:  #FAFBFC;
  color: gray;
}
.method2-active{
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #EDEDED;
  border-bottom-style: hidden;
  // background-color: #FAFBFC;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: -1px;
  position: relative;
}
.method2-in-active{
  border-radius: 12px 12px 0px 0px;
  border-bottom-style: hidden;
}
.method2:hover{
  // background-color:  #FAFBFC;
  color: gray;
}
.method2-active > label {
  margin-bottom: 0px;
}
.method2-in-active > label {
  margin-bottom: 0px;
  color: #cccccc;
}

@media (min-width: 767.98px) {
  .payment-setting-method .table,
  .payment-setting-method .method2 {
    min-width: 915px;
  }
}

.payment-setting-method .table thead tr:nth-child(2) th:first-child {
  border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
}
.payment-setting-method .table thead tr:nth-child(2) th:last-child {
  border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
}
.payment-setting-method .table thead tr:nth-child(2) th {
  border-top: 1px solid #FFFFFF;
}

.vendor-percent {
  padding-left: 6rem;
}

@media (max-width: 576px) {
  .vendor-percent {
    padding-left: 0rem;
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* payment setting register end */

/* overtime set minute range start */

.overtime-set-minute .table {
  min-width: 970px;
}

/* overtime set minute range end */

/* holiday setup start */

.holiday-list-table {
  min-width: 945px;
}

/* holiday setup end */

/** leave type registration start */

.pay-basic-total-fixed .btn {
  padding: 0.25rem 1.25rem;
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
  -webkit-margin-after: 3px;
  margin-block-end: 3px;
  -webkit-margin-before: 3px;
  margin-block-start: 3px;
}

@media (min-width: 992px) {
  .leave-currency .toggle-switch {
    margin-left: 0 !important;
  }
}

@media (max-width: 420px) {
  .pay-basic-total-fixed {
    display: block;
    width: 100% !important;
  }
  .pay-basic-total-fixed .btn {
    display: block;
    text-align: center;
    width: 100%;
  }
}


/** leave type registration end */

/** tenant registration start */

.save-key {
  width: calc(100% - 100px);
  margin-right: 75px;
  display: inline-block;
}

.update-key {
  width: 100%;
  margin-right: 0px;
  display: block;
}

.key-name .generate-btn {
  width: 80px;
  height: calc(2em + 0.1rem);
  padding: 0.1rem;
  background-image: linear-gradient(to bottom, #7bbe35, #FFFFFF) !important;
  border-radius: 7px;
  border: 1px solid #CDCCCC;
  font-size: 0.9rem;
  right: 20px;
  position: absolute;
  margin-top: 2px;
  min-width: auto;
}

#landlord-chk input[type="checkbox"]:before {
  background-color: #FFFFFF;
  border: 1px solid #333333;
}

#landlord-chk input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

#landlord-chk input[type="checkbox"]:checked {
  -moz-appearance:none;
  -o-appearance:none;
  -webkit-appearance: none;
}

#landlord-chk input[type="checkbox"]:checked:before {
  content: "";
  background-color: #9098AF;
}

#landlord-chk input[type="checkbox"]:disabled:before {
// #landlord-chk input[type="checkbox"]:disabled {
  background-color: #A2ABB9 !important;
}

.eye {
  position: absolute;
  z-index: 0;
  top: 15%;
  right: 20px;
}

/** tenant registration end  */